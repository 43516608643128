












































import { Component, Watch, Vue } from 'vue-property-decorator'
import controller from '@/app/ui/controllers/UserManagementController'
import DataTable from '@/app/ui/components/DataTable/index.vue'
import TextInput from '@/app/ui/components/TextInput/index.vue'
import PaginationNav from '@/app/ui/components/PaginationNav/index.vue'
import Button from '@/app/ui/components/Button/index.vue'
import { Utils } from '@/app/infrastructures/misc'
import { USER_MANAGEMENT_PAGINATION } from '@/app/infrastructures/misc/Constants'
import { Dictionary } from 'vue-router/types/router'

@Component({
  components: {
    DataTable,
    PaginationNav,
    Button,
    TextInput,
  },
})
export default class UserManagement extends Vue {
  isGuest = Utils.isGuest()
  controller = controller
  parameters = {
    page: 1,
    perPage: USER_MANAGEMENT_PAGINATION,
    keyword: undefined,
  }
  headers = ['id', 'Email', 'Role', 'Access']

  created(): void {
    if (this.$route.query instanceof Object) {
      const queries = this.$route.query as Record<string, never>
      this.parameters = {
        page: queries.page || 1,
        perPage: queries.perPage || USER_MANAGEMENT_PAGINATION,
        keyword:
          String(queries.keyword).length >= 3 ? queries.keyword : undefined,
      }
    }
    this.fetchUsers()
  }

  get userAccessDataList(): Array<string | number | undefined>[] {
    return controller.userData.map(user => [
      user.id,
      user.email,
      Utils.roleName(user.role as string),
      user.accessMenu,
    ])
  }

  @Watch('parameters', { deep: true })
  onParamsChanged(
    val: Dictionary<string | (string | null)[] | null | undefined> | undefined
  ): void {
    this.$router.push({
      query: {
        ...val,
      },
    })
  }

  private search = Utils.debounce((keyword: string) => {
    if (keyword.length >= 3 || keyword === '') {
      this.fetchUsers(true)
    }
  }, 500)

  private fetchUsers(reset = false) {
    if (reset) this.parameters.page = 1

    controller.getUsers({
      ...this.parameters,
      keyword: encodeURIComponent(this.parameters.keyword || ''),
    })
  }
}

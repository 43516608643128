import { container } from 'tsyringe'
import Vue from 'vue'
import {
  VuexModule,
  Module,
  Mutation,
  Action,
  getModule,
} from 'vuex-module-decorators'
import {
  AccessMenuRequest,
  CreateUserRequest,
  UpdateUserRequest,
} from '@/data/payload/api/UserManagementRequest'
import store from '@/app/ui/store'
import { UserManagementPresenter } from '../presenters/UserManagementPresenter'
import { EventBus, EventBusConstants, Utils } from '@/app/infrastructures/misc'
import { AccessMenu, Account, Accounts, Role } from '@/domain/entities/Account'
import { Pagination } from '@/domain/entities/Pagination'
import RouterConstant from '../router/constants'
import { Menu } from '@/app/infrastructures/misc/MenuDecorator'

export interface UserManagementState {
  isLoading: boolean
  isLoadingRoles: boolean
  isLoadingMenus: boolean
  roleData: Role[]
  accessMenuData: AccessMenu[]
  userData: Account[]
  paginationData: Pagination
  userDetail: Account
  isDeleteAccountSuccess: boolean
  isCreateAccountSuccess: boolean
  isUpdateAccountSuccess: boolean
}

@Module({ namespaced: true, store, name: 'user-management', dynamic: true })
class UserManagementController extends VuexModule
  implements UserManagementState {
  private presenter: UserManagementPresenter = container.resolve(
    UserManagementPresenter
  )
  public isLoading = false
  public isLoadingRoles = false
  public isLoadingMenus = false
  public roleData = [new Role()]
  public accessMenuData = [new AccessMenu()]
  public userData = [new Account()]
  public paginationData = new Pagination()
  public userDetail = new Account()
  public isDeleteAccountSuccess = false
  public isCreateAccountSuccess = false
  public isUpdateAccountSuccess = false

  @Action({ rawError: true })
  public getRoles() {
    this.setLoadingRoles(true)

    this.presenter
      .getRoles(new Map())
      .then(res => {
        this.setRoles(res)
      })
      .catch(error => {
        Vue.notify({
          title: 'Fetch Role Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoadingRoles(false)
      })
  }

  @Action({ rawError: true })
  public getUsers(params: Record<string, any>) {
    this.setLoading(true)
    const formattedParams = Utils.toInstance(
      new Map(),
      JSON.stringify(params),
      'snake_case'
    )

    this.presenter
      .getUsers(formattedParams)
      .then(res => {
        this.setUsers(res)
      })
      .catch(error => {
        Vue.notify({
          title: 'Fetch Users Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public getUser(accountId: string) {
    this.setLoading(true)

    this.presenter
      .getUser(accountId)
      .then(res => {
        this.setUserDetail(res)
      })
      .catch(error => {
        Vue.notify({
          title: 'Fetch User Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public getMenus() {
    this.setLoadingMenus(true)

    this.presenter
      .getMenus(new Map())
      .then(res => {
        this.setAccessMenus(res)
      })
      .catch(error => {
        Vue.notify({
          title: 'Fetch Menu List Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoadingMenus(false)
      })
  }

  @Action({ rawError: true })
  public createAccount(form: {
    email: string
    role: string
    accessMenu: number[]
  }) {
    this.setLoading(true)

    this.presenter
      .createUser(
        new CreateUserRequest(
          form.email.toLowerCase().trim(),
          form.role,
          Utils.sanitizeMenu(form.role, form.accessMenu)
        )
      )
      .then(() => {
        EventBus.$emit(EventBusConstants.CREATE_ACCOUNT_SUCCESS, {})
        this.setIsCreateAccountSuccess(true)
      })
      .catch(error => {
        Vue.notify({
          title: 'Create Account Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public updateAccount(form: {
    accountId: string
    role: string
    accessMenu: number[]
  }) {
    this.setLoading(true)

    this.presenter
      .updateUser(
        form.accountId,
        new UpdateUserRequest(
          form.role,
          Utils.sanitizeMenu(form.role, form.accessMenu)
        )
      )
      .then(() => {
        EventBus.$emit(EventBusConstants.UPDATE_ACCOUNT_SUCCESS, {})
        this.setIsUpdateAccountSuccess(true)
      })
      .catch(error => {
        Vue.notify({
          title: 'Update Account Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public deleteAccount(accountId: string) {
    this.setLoading(true)

    this.presenter
      .deleteUser(accountId)
      .then(() => {
        EventBus.$emit(EventBusConstants.DELETE_ACCOUNT_SUCCESS, {})
        this.setIsDeleteAccountSuccess(true)
      })
      .catch(error => {
        Vue.notify({
          title: 'Delete Account Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public async syncMenu() {
    const localMenuList: Menu[] = Object.values(RouterConstant).filter(
      menu => !menu.hide
    )

    const serverMenuList = this.accessMenuData.reduce(
      (acc, curr) => ({ ...acc, [curr.slug as string]: curr }),
      {}
    ) as Record<string, AccessMenu>

    const promise: Promise<boolean>[] = []
    localMenuList.forEach(accessMenu => {
      const current = serverMenuList[accessMenu.slug as string]
      const payload = new AccessMenuRequest(
        accessMenu.slug,
        accessMenu.label,
        accessMenu.group as string
      )

      // Add new menu
      if (current === undefined) {
        promise.push(this.presenter.addMenu(payload))

        return
      }

      // Update Menu
      if (
        accessMenu.label !== current.name ||
        accessMenu.group !== current.group
      ) {
        promise.push(this.presenter.updateMenu(current.id as number, payload))
      }
    })

    // Delete Menu
    const localMenuListArray = localMenuList.map(menu => menu.slug)
    Object.keys(serverMenuList)
      .filter(menu => localMenuListArray.indexOf(menu) == -1)
      .forEach(deleted => {
        promise.push(
          this.presenter.deleteMenu(
            serverMenuList[deleted as string].id as number
          )
        )
      })

    if (promise.length >= 1) {
      Promise.all(promise)
        .then(() => {
          this.getMenus()
        })
        .catch(error => {
          Vue.notify({
            title: 'Failed to Sync Menu',
            text:
              error.status === 400 || error.status === 422
                ? error.error.message.en
                : 'Something wrong',
            type: 'error',
            duration: 5000,
          })
        })
    }
  }

  @Mutation
  private setUserDetail(user: Account) {
    this.userDetail = user
  }

  @Mutation
  private setUsers(users: Accounts) {
    this.paginationData = users.pagination as Pagination
    this.userData = users.data as Account[]
  }

  @Mutation
  private setRoles(roles: Role[]) {
    this.roleData = roles
  }

  @Mutation
  private setAccessMenus(menus: AccessMenu[]) {
    this.accessMenuData = menus
  }

  @Mutation
  public setLoading(bool: boolean) {
    this.isLoading = bool
  }

  @Mutation
  private setLoadingRoles(bool: boolean) {
    this.isLoadingRoles = bool
  }

  @Mutation
  private setLoadingMenus(bool: boolean) {
    this.isLoadingMenus = bool
  }

  @Mutation
  public setIsDeleteAccountSuccess(bool: boolean): void {
    this.isDeleteAccountSuccess = bool
  }

  @Mutation
  public setIsCreateAccountSuccess(bool: boolean): void {
    this.isCreateAccountSuccess = bool
  }

  @Mutation
  public setIsUpdateAccountSuccess(bool: boolean): void {
    this.isUpdateAccountSuccess = bool
  }
}

export default getModule(UserManagementController)
